<template>
  <v-app>
    <EnvironmentSystemBar :environment="environment"> </EnvironmentSystemBar>
    <v-app-bar app dark class="bg-black">
      <v-container>
        <img class="logo" alt="Sony" src="@/assets/logo-white.png" />
      </v-container>
    </v-app-bar>
    <v-main class="login-bg">
      <v-container class="fill-height">
        <v-row justify="center">
          <v-col lg="6" md="8" sm="10" xs="12">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer absolute>
      <Footer></Footer>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Footer from "../../components/Footer.vue";
import EnvironmentSystemBar from "../../gapp-components/components/display/EnvironmentSystemBar.vue";

export default {
  name: "LayoutPublic",
  components: { EnvironmentSystemBar, Footer },
  async mounted() {
    let EVE_KEY = process.env.VUE_APP_PROGRAM_EVE_KEY;
    if (!this.selectedProgram || this.selectedProgram.programKey != EVE_KEY) {
      await this.$api.get("/api/programs/byKey/" + EVE_KEY + "/public").then(({ data }) => {
        let program = data;
        return Promise.all([
          this.$auth.storeSelectProgram(program),
          this.$auth.storeSelectClient(program.programGroup.client)
        ]);
      });
    }
  },
  computed: {
    ...mapGetters(["selectedProgram"]),
    environment() {
      return process.env;
    }
  }
};
</script>
